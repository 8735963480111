var hooks = {}
// Format like:
// {"commandname":{"before":[func1,func2...],"in":[func1,func2,...],"after":[func1,func2,...]}}
var hook = {} // for storage the hook system
hook.register = function (when, name, func) {
    if (!hooks[name]) {
        // Create
        hooks[name] = { "before": [], "in": [], "after": [] }
    }
    // And the push the command
    hooks[name][when].push(func)
}
hook.run = function (when, name, args) {
    let funcs = hooks[name] ?? { "before": [], "in": [], "after": [] }
    funcs[when].forEach(element => {
        args = element(args)
        if (args == false) {
            return false //prevent this event run
        }
    })
    return args
}
var antiLatex = true;

/**
 * @param {String} query
 * @returns {Element|HTMLElement}
 */
function $(query) {
	return document.querySelector(query);
}

function localStorageGet(key) {
	try {
		return window.localStorage[key]
	} catch (e) { }
}

function localStorageSet(key, val) {
	try {
		window.localStorage[key] = val
	} catch (e) { }
}

/**
 * @param {String} id
 * @returns {Element|HTMLElement}
 */
function $id(id) {
	return document.getElementById(id)
}

/* ---Markdown--- */

// initialize markdown engine
var markdownOptions = {
	html: false,
	xhtmlOut: false,
	breaks: true,
	linkify: true,
	linkTarget: '_blank" rel="noreferrer',
	typographer: true,
	quotes: `""''`,

	doHighlight: true,
	langPrefix: 'hljs language-',
	highlight: function (str, lang) {
		if (!markdownOptions.doHighlight || !window.hljs) { return ''; }

		if (lang && hljs.getLanguage(lang)) {
			try {
				return hljs.highlight(lang, str).value;
			} catch (__) { }
		}

		try {
			return hljs.highlightAuto(str).value;
		} catch (__) { }

		return '';
	}
};

var md = new Remarkable('full', markdownOptions);

// image handler
var allowImages = false;
var whitelistDisabled = false;
var camo=false || localStorageGet("test-camo")!=undefined
var camoAddrs=[
	"https://camo.hach.chat/"
];
var imgHostWhitelist = [
	'i.imgur.com',
	'imgur.com',
	'share.lyka.pro',
	'cdn.discordapp.com',
	'i.gyazo.com',
	'img.thz.cool',
	'i.loli.net', 's2.loli.net', //SM-MS图床
	's1.ax1x.com', 's2.ax1x.com', 'z3.ax1x.com', 's4.ax1x.com', //路过图床
	'i.postimg.cc', //postimages图床
	'mrpig.eu.org', //慕容猪的图床
	'gimg2.baidu.com', //百度
	'files.catbox.moe', //catbox
	'img.liyuv.top', //李鱼图床
	location.hostname, // 允许我自己
	'bed.paperee.repl.co', 'filebed.paperee.guru', // 纸片君ee的纸床
	'imagebed.s3.bitiful.net', //Dr0让加的
	'img1.imgtp.com', 'imgtp.com', // imgtp
	'api.helloos.eu.org', // HelloOsMe's API
	'cdn.luogu.com.cn', // luogu
	'i.ibb.co', // imgbb
	'picshack.net',
	'hcimg.s3.bitiful.net', //24a's
]; // Some are copied from https://github.com/ZhangChat-Dev-Group/ZhangChat/

function getDomain(link) {
	try {
		return new URL(link).hostname
	} catch (err) {
		return new URL("http://example.com").hostname
	}
}

function isWhiteListed(link) {
	return whitelistDisabled || imgHostWhitelist.indexOf(getDomain(link)) !== -1;
}

function mdEscape(str) {
	return str.replace(/(?=(\\|`|\*|_|\{|\}|\[|\]|\(|\)|#|\+|-|\.|!|\||=|\^|~|\$|>|<|'))/g, '\\')
}

md.renderer.rules.image = function (tokens, idx, options) {
	var src = Remarkable.utils.escapeHtml(tokens[idx].src);

	if (isWhiteListed(src) && allowImages) {
		var imgSrc = ' src="' + Remarkable.utils.escapeHtml(tokens[idx].src) + '"';
		var title = tokens[idx].title ? (' title="' + Remarkable.utils.escapeHtml(Remarkable.utils.replaceEntities(tokens[idx].title)) + '"') : '';
		var alt = ' alt="' + (tokens[idx].alt ? Remarkable.utils.escapeHtml(Remarkable.utils.replaceEntities(Remarkable.utils.unescapeMd(tokens[idx].alt))) : '') + '"';
		var suffix = options.xhtmlOut ? ' /' : '';
		var scrollOnload = isAtBottom() ? ' onload="window.scrollTo(0, document.body.scrollHeight)"' : '';
		return '<a href="' + src + '" target="_blank" rel="noreferrer"><img' + scrollOnload + imgSrc + alt + title + suffix + ' referrerpolicy="no-referrer"></a>';
	}else if(allowImages && camo){
		var proxiedAddr = camoAddrs[Math.floor(Math.random()*camoAddrs.length)]+"?proxyUrl="+tokens[idx].src
		var imgSrc = ' src="' + Remarkable.utils.escapeHtml(proxiedAddr) + '"';
		var title = tokens[idx].title ? (' title="' + Remarkable.utils.escapeHtml(Remarkable.utils.replaceEntities(tokens[idx].title)) + '"') : '';
		var alt = ' alt="' + (tokens[idx].alt ? Remarkable.utils.escapeHtml(Remarkable.utils.replaceEntities(Remarkable.utils.unescapeMd(tokens[idx].alt))) : '') + '"';
		var suffix = options.xhtmlOut ? ' /' : '';
		var scrollOnload = isAtBottom() ? ' onload="window.scrollTo(0, document.body.scrollHeight)"' : '';
		return '<a href="' + proxiedAddr + '" target="_blank" rel="noreferrer"><img' + scrollOnload + imgSrc + alt + title + suffix + ` referrerpolicy="no-referrer" onerror="this.style.display='none';let addr=document.createElement('p');addr.innerText='${Remarkable.utils.escapeHtml(Remarkable.utils.replaceEntities(src))}';this.parentElement.appendChild(addr)"></a>`;
	}

	return '<a href="' + src + '" target="_blank" rel="noreferrer">' + Remarkable.utils.escapeHtml(Remarkable.utils.replaceEntities(src)) + '</a>';
};

md.renderer.rules.link_open = function (tokens, idx, options) {
	var title = tokens[idx].title ? (' title="' + Remarkable.utils.escapeHtml(Remarkable.utils.replaceEntities(tokens[idx].title)) + '"') : '';
	var target = options.linkTarget ? (' target="' + options.linkTarget + '"') : '';
	return '<a rel="noreferrer" onclick="return mdClick(event)" href="' + Remarkable.utils.escapeHtml(tokens[idx].href) + '"' + title + target + '>';
};

md.renderer.rules.text = function (tokens, idx) {
	tokens[idx].content = Remarkable.utils.escapeHtml(tokens[idx].content);

	if (tokens[idx].content.indexOf('?') !== -1) {
		tokens[idx].content = tokens[idx].content.replace(/(^|\s)(\?)\S+?(?=[,.!?:)]?\s|$)/gm, function (match) {
			var channelLink = Remarkable.utils.escapeHtml(Remarkable.utils.replaceEntities(match.trim()));
			var whiteSpace = '';
			if (match[0] !== '?') {
				whiteSpace = match[0];
			}
			return whiteSpace + '<a href="' + channelLink + '" target="_blank">' + channelLink + '</a>';
		});
	}

	return tokens[idx].content;
};

md.use(remarkableKatex);

/* ---Some functions and texts to be used later--- */

function mdClick(e) {
	e.stopPropagation();
	e = e || window.event;
	var targ = e.target || e.srcElement || e;
	if (targ.nodeType == 3) targ = targ.parentNode;
	return verifyLink(targ);
}

function verifyLink(link) {
	var linkHref = Remarkable.utils.escapeHtml(Remarkable.utils.replaceEntities(link.href));
	if (linkHref !== link.innerHTML) {
		return confirm(i18ntranslate('Warning, please verify this is where you want to go: ' + linkHref, 'prompt'));
	}

	return true;
}

var verifyNickname = function (nick) {
	return /^[a-zA-Z0-9_]{1,24}$/.test(nick);
}

//LaTeX weapon and too-many-quotes weapon defence
function verifyMessage(args) {
	// iOS Safari doesn't support zero-width assertion
	if (!antiLatex) return true;
	if (/([^\s^_]+[\^_]{){8,}|(^|\n)(>[^>\n]*){5,}/.test(args.text) || /\$.*[[{]\d+(?:mm|pt|bp|dd|pc|sp|cm|cc|in|ex|em|px)[\]}].*\$/.test(args.text) || /\$\$[\s\S]*[[{]\d+(?:mm|pt|bp|dd|pc|sp|cm|cc|in|ex|em|px)[\]}][\s\S]*\$\$/.test(args.text) || /^[ \t]*(?:[+\-*][ \t]){3,}/m.test(args.text)) {
		return false;
	} else {
		return true;
	}
}

function checkLong(text) {
	return msgLineLength(text) > 8
}

function msgLineLength(text) {
	let lines = 0;
	let byteCount = 0;
	let currentSubstring = '';
	for (let i = 0; i < text.length; i++) {
		let byteLength = text.charCodeAt(i) <= 127 ? 1 : 2;
		if (text[i] === '\n') {
			if (byteCount + byteLength >= 72) {
				lines += 1;
				byteCount = 0;
				currentSubstring = '';
			}
			currentSubstring += text[i];
			lines += 1;
			byteCount = 0;
			currentSubstring = '';
		} else if (byteCount + byteLength > 72) {
			lines += 1;
			byteCount = byteLength;
			currentSubstring = text[i];
		} else {
			byteCount += byteLength;
			currentSubstring += text[i];
		}
	}
	if (currentSubstring !== '') lines += 1
	text.split("\n").forEach(e => {
		if (e.startsWith("#")) lines += 1
	})
	return lines;
}

var input = $id('chatinput');

function insertAtCursor(text) {
	var start = input.selectionStart || 0;
	var before = input.value.substr(0, start);
	var after = input.value.substr(start);

	before += text;
	input.value = before + after;
	input.selectionStart = input.selectionEnd = before.length;

	updateInputSize();
}

function backspaceAtCursor(length = 1) {
	var start = input.selectionStart || 0;
	var before = input.value.substr(0, start);
	var after = input.value.substr(start);

	before = before.slice(0, -length);
	input.value = before + after;
	input.selectionStart = input.selectionEnd = before.length;

	updateInputSize();
}

/** Notification switch and local storage behavior **/
var notifySwitch = document.getElementById("notify-switch")
var notifySetting = localStorageGet("notify-api")
var notifyPermissionExplained = 0; // 1 = granted msg shown, -1 = denied message shown

// Inital request for notifications permission
function RequestNotifyPermission() {
	try {
		var notifyPromise = Notification.requestPermission();
		if (notifyPromise) {
			notifyPromise.then(function (result) {
				console.log("Crosst.Chat notification permission: " + result);
				if (result === "granted") {
					if (notifyPermissionExplained === 0) {
						pushMessage({
							cmd: "chat",
							nick: "*",
							text: "Notifications permission granted.",
							time: null
						});
						notifyPermissionExplained = 1;
					}
					return false;
				} else {
					if (notifyPermissionExplained === 0) {
						pushMessage({
							cmd: "chat",
							nick: "*",
							text: "Notifications permission denied, you won't be notified if someone @mentions you.",
							time: null
						});
						notifyPermissionExplained = -1;
					}
					return true;
				}
			});
		}
	} catch (error) {
		pushMessage({
			cmd: "chat",
			nick: "*",
			text: "Unable to create a notification.",
			time: null
		});
		console.error("An error occured trying to request notification permissions. This browser might not support desktop notifications.\nDetails:")
		console.error(error)
		return false;
	}
}

// Update localStorage with value of checkbox
notifySwitch.addEventListener('change', (event) => {
	if (event.target.checked) {
		RequestNotifyPermission();
	}
	localStorageSet("notify-api", notifySwitch.checked)
})
// Check if localStorage value is set, defaults to OFF
if (notifySetting === null) {
	localStorageSet("notify-api", "false")
	notifySwitch.checked = false
}
// Configure notifySwitch checkbox element
if (notifySetting === "true" || notifySetting === true) {
	notifySwitch.checked = true
} else if (notifySetting === "false" || notifySetting === false) {
	notifySwitch.checked = false
}

/** Sound switch and local storage behavior **/
var soundSwitch = document.getElementById("sound-switch")
var notifySetting = localStorageGet("notify-sound")

// Update localStorage with value of checkbox
soundSwitch.addEventListener('change', (event) => {
	localStorageSet("notify-sound", soundSwitch.checked)
})
// Check if localStorage value is set, defaults to OFF
if (notifySetting === null) {
	localStorageSet("notify-sound", "false")
	soundSwitch.checked = false
}
// Configure soundSwitch checkbox element
if (notifySetting === "true" || notifySetting === true) {
	soundSwitch.checked = true
} else if (notifySetting === "false" || notifySetting === false) {
	soundSwitch.checked = false
}

// Create a new notification after checking if permission has been granted
function spawnNotification(title, body) {
	// Let's check if the browser supports notifications
	if (!("Notification" in window)) {
		console.error("This browser does not support desktop notification");
	} else if (Notification.permission === "granted") { // Check if notification permissions are already given
		// If it's okay let's create a notification
		var options = {
			body: body,
			icon: "/favicon-96x96.png"
		};
		var n = new Notification(title, options);
	}
	// Otherwise, we need to ask the user for permission
	else if (Notification.permission !== "denied") {
		if (RequestNotifyPermission()) {
			var options = {
				body: body,
				icon: "/favicon-96x96.png"
			};
			var n = new Notification(title, options);
		}
	} else if (Notification.permission == "denied") {
		// At last, if the user has denied notifications, and you
		// want to be respectful, there is no need to bother them any more.
	}
}

function notify(args) {
	// Spawn notification if enabled
	if (notifySwitch.checked) {
		spawnNotification("?" + myChannel + "  �  " + args.nick, args.text)
	}

	// Play sound if enabled
	if (soundSwitch.checked) {
		var soundPromise = document.getElementById("notify-sound").play();
		if (soundPromise) {
			soundPromise.catch(function (error) {
				console.error("Problem playing sound:\n" + error);
			});
		}
	}
}
//https://github.com/hack-chat/main/pull/184
//select "chatinput" on "/"
document.addEventListener("keydown", e => {
	if (e.key === '/' && document.getElementById("chatinput") != document.activeElement) {
		e.preventDefault();
		document.getElementById("chatinput").focus();
	}
});

//make frontpage have a getter
//https://developer.mozilla.org/zh-CN/docs/Web/JavaScript/Reference/Functions/get#%E4%BD%BF%E7%94%A8defineproperty%E5%9C%A8%E7%8E%B0%E6%9C%89%E5%AF%B9%E8%B1%A1%E4%B8%8A%E5%AE%9A%E4%B9%89_getter
function getHomepage() {
  
	ws = new WebSocket( ws_url );
  
	ws.onerror = function () {
	  pushMessage({ text: "# dx_xb\n连接聊天室服务器失败，请稍候重试。\n**如果这个问题持续出现，请立刻联系 mail@henrize.kim 感谢您的理解和支持**", nick: '!'});
	}
  
	var reqSent = false;
  
	ws.onopen = function () {
	  if (!reqSent) {
		send({ cmd: 'getinfo' });
		reqSent = true;
	  }
	  return;
	}
  
	ws.onmessage = function (message) {
	  var args = JSON.parse(message.data);
	  if (args.ver == undefined) {
		args.ver = "获取失败";
		args.online = "获取失败";
	  }
	  var homeText = "# 十字街\n##### " + args.ver + " 在线人数：" + args.online + " 客户端：CrosSt++ " + CSCPP_VER + "\n-----\n欢迎来到十字街，这是一个简洁轻小的聊天室网站。\n第一次来十字街？来 **[公共聊天室](?公共聊天室)** 看看吧！\n你也可以创建自己的聊天室。\n站长邮箱：mail@henrize.kim（维护中，无法发信）\n十字街源码：[github.com/CrosSt-Chat/CSC-main](https://github.com/CrosSt-Chat/CSC-main/)\n-----\n在使用本网站时，您应当遵守中华人民共和国的有关规定。\n如果您不在中国大陆范围内居住，您还应当同时遵守当地的法律规定。\nCrosSt.Chat Dev Team - 2020/02/29\nHave a nice chat!";    pushMessage({ text: homeText });
	}
  }


var info = {}

var channels = [
	[`?your-channel`, `?programming`, `?lounge`],
	[`?meta`, `?math`, `?physics`, `?chemistry`],
	[`?technology`, `?games`, `?banana`],
	[`?test`, `?your-channell`, `?china`, `?chinese`, `?kt1j8rpc`],
]

function pushFrontPage() {
	pushMessage({ text: frontpage() }, { isHtml: true, i18n: false, noFold: true })
}

/* ---Some variables to be used--- */

var myNick = localStorageGet('my-nick') || '';
var myColor = localStorageGet('my-color') || null;//hex color value for autocolor
var myChannel = decodeURIComponent(window.location.search.replace(/^\?/, ''))

var lastSent = [""];
var lastSentPos = 0;

var kolorful = false
var devMode = false

//message log
var jsonLog = '';
var readableLog = '';

var templateStr = '';

var replacement = '\*\*'
var hide = ''
var replace = ''

var lastcid;

var seconds = {
	'join': {
		'times': [],
		'last': (new Date).getTime(),
	},
}

var lastMentioned = ''


function reply(args) {//from crosst.chat
	let replyText = '';
	let originalText = args.text;
	let overlongText = false;

	// Cut overlong text
	if (originalText.length > 350) {
		replyText = originalText.slice(0, 350);
		overlongText = true;
	}

	// Add nickname
	if (args.trip) {
		replyText = '>' + args.trip + ' ' + args.nick + '：\n';
	} else {
		replyText = '>' + args.nick + '：\n';
	}

	// Split text by line
	originalText = originalText.split('\n');

	// Cut overlong lines
	if (originalText.length >= 8) {
		originalText = originalText.slice(0, 8);
		overlongText = true;
	}

	for (let replyLine of originalText) {
		// Cut third replied text
		if (!replyLine.startsWith('>>')) {
			replyText += '>' + replyLine + '\n';
		}
	}

	// Add elipsis if text is cutted
	if (overlongText) {
		replyText += '>……\n';
	}
	replyText += '\n';


	// Add mention when reply to others
	if (args.nick != myNick.split('#')[0]) {
		var nick = args.nick
		let at = '@'
		if ($id('soft-mention').checked) { at += ' ' }
		replyText += at + nick + ' ';
	}

	// Insert reply text
	replyText += input.value;

	input.value = '';
	insertAtCursor(replyText);
	input.focus();
}

/* ---Session Command--- */

function getInfo() {
	return new Promise(function (resolve, reject) {
		let ws = new WebSocket(ws_url);

		ws.onopen = function () {
			this.send(JSON.stringify({ cmd: "session", isBot: false }))
		}

		ws.onmessage = function (message) {
			let data = JSON.parse(message.data)
			if (data.cmd != 'session') {
				return
			}
			info.public = data.public
			info.chans = data.chans
			info.users = data.users
			if (should_get_info) {
				for (let i = 0; i < channels.length; i++) {
					let line = channels[i]
					for (let j = 0; j < line.length; j++) {
						let channel = line[j]
						let user_count = info.public[channel.slice(1)]
						if (typeof user_count == 'number') {
							channel = channel + ' ' + '(' + user_count + ')'
						} else {
							channel = channel + ' ' + '(\\\\)'
						}
						line[j] = channel
					}
					channels[i] = line
				}
			}
			this.close()
			resolve()
		}
	})
}

/* ---Window and input field and sidebar stuffs--- */

var windowActive = true;
var unread = 0;

window.onfocus = function () {
	windowActive = true;

	updateTitle();
}

window.onblur = function () {
	windowActive = false;
}

window.onscroll = function () {
	if (isAtBottom()) {
		updateTitle();
	}
}

function isAtBottom() {
	return (window.innerHeight + window.scrollY) >= (document.body.scrollHeight - 1);
}

function updateTitle() {
	if (myChannel == '') {
		unread = 0;
		return;
	}

	if (windowActive && isAtBottom()) {
		unread = 0;
	}

	var title;
	if (myChannel) {
		title = myChannel + " - crosst.chat++";
	} else {
		title = "crosst.chat++";
	}

	if (unread > 0) {
		title = '(' + unread + ') ' + title;
	}

	document.title = title;
}

$id('footer').onclick = function () {
	input.focus();
}

var keyActions = {
	send() {
		if (!wasConnected) {
			pushMessage({ nick: '*', text: "Attempting to reconnect. . ." })
			join(myChannel);
		}

		// Submit message
		if (input.value != '') {
			let text = input.value
			if ($id('auto-precaution').checked && checkLong(text) && (!text.startsWith('/') || text.startsWith('/me') || text.startsWith('//'))) {
				send({ cmd: 'emote', text: 'Warning: Long message after 3 second | 警告：3秒后将发送长消息' })
				sendInputContent(3000)
			} else {
				sendInputContent()
			}
		}
	},

	up() {
		if (lastSentPos == 0) {
			lastSent[0] = input.value;
		}

		lastSentPos += 1;
		input.value = lastSent[lastSentPos];
		input.selectionStart = input.selectionEnd = input.value.length;

		updateInputSize();
	},

	down() {
		lastSentPos -= 1;
		input.value = lastSent[lastSentPos];
		input.selectionStart = input.selectionEnd = 0;

		updateInputSize();
	},

	tab() {
		var pos = input.selectionStart || 0;
		var text = input.value;
		var index = text.lastIndexOf('@', pos);

		var autocompletedNick = false;

		if (index >= 1 && index == pos - 1 && text.slice(index - 1, pos).match(/^@@$/)) {
			autocompletedNick = true;
			backspaceAtCursor(1);
			insertAtCursor(onlineUsers.join(' @') + " ");
		} else if (index >= 0 && index == pos - 1) {
			autocompletedNick = true;
			if (lastMentioned.length > 0) {
				insertAtCursor(lastMentioned + " ");
			} else {
				insertAtCursor(myNick.split('#')[0] + " ");
				lastMentioned = myNick.split('#')[0]
			}
		} else if (index >= 0) {
			var stub = text.substring(index + 1, pos);

			// Search for nick beginning with stub
			var nicks = onlineUsers.filter(nick => nick.indexOf(stub) == 0);

			if (nicks.length == 0) {
				nicks = onlineUsers.filter(
					nick => nick.toLowerCase().indexOf(stub.toLowerCase()) == 0
				)
			}

			if (nicks.length > 0) {
				autocompletedNick = true;
				if (nicks.length == 1) {
					backspaceAtCursor(stub.length);
					insertAtCursor(nicks[0] + " ");
					lastMentioned = nicks[0]
				}
			}
		}

		// Since we did not insert a nick, we insert a tab character
		if (!autocompletedNick) {
			insertAtCursor('\t');
		}
	},
}

input.onkeydown = function (e) {
	if (e.keyCode == 13 /* ENTER */ && !e.shiftKey) {
		e.preventDefault();

		keyActions.send();
	} else if (e.keyCode == 38 /* UP */) {
		// Restore previous sent messages
		if (input.selectionStart === 0 && lastSentPos < lastSent.length - 1) {
			e.preventDefault();

			keyActions.up();
		}
	} else if (e.keyCode == 40 /* DOWN */) {
		if (input.selectionStart === input.value.length && lastSentPos > 0) {
			e.preventDefault();

			keyActions.down();
		}
	} else if (e.keyCode == 27 /* ESC */) {
		e.preventDefault();

		// Clear input field
		input.value = "";
		lastSentPos = 0;
		lastSent[lastSentPos] = "";

		updateInputSize();
	} else if (e.keyCode == 9 /* TAB */) {
		// Tab complete nicknames starting with @

		if (e.ctrlKey) {
			// Skip autocompletion and tab insertion if user is pressing ctrl
			// ctrl-tab is used by browsers to cycle through tabs
			return;
		}
		e.preventDefault();

		keyActions.tab();
	}
}

function sendInputContent(delay) {
	let text = input.value;
	input.value = '';

	if (templateStr && !isAnsweringCaptcha) {
		if (templateStr.indexOf('%m') > -1) {
			text = templateStr.replace('%m', text);
		}
	}
	if (!delay) {
		silentSendText(text)
	} else {
		setTimeout(silentSendText, delay, text)
	}

	lastSent[0] = text;
	lastSent.unshift("");
	lastSentPos = 0;

	updateInputSize();
}

function silentSendText(text) {
	if (kolorful) {
		send({ cmd: 'changecolor', color: Math.floor(Math.random() * 0xffffff).toString(16).padEnd(6, "0") });
	}

	if (isAnsweringCaptcha && text != text.toUpperCase()) {
		text = text.toUpperCase();
		pushMessage({ nick: '*', text: 'Automatically converted into upper case by client.' });
	}

	if (purgatory) {
		send({ cmd: 'emote', text: text });
	} else {
		// Hook localCmds
		if(isSPCmd(text)){
			callSPcmd(text)
		}else{
			send({ cmd: 'chat', text: text });
		}
	}
	return text;
}

function updateInputSize() {
	var atBottom = isAtBottom();

	input.style.height = 0;
	input.style.height = input.scrollHeight + 'px';
	document.body.style.marginBottom = $id('footer').offsetHeight + 'px';

	if (atBottom) {
		window.scrollTo(0, document.body.scrollHeight);
	}
}

input.oninput = function () {
	updateInputSize();
}

let i18n = new Map([
    [
        'zh-CN', {
            ui: new Map([
                /* UI text */
                ['Pin sidebar', '固定侧边栏'],
                ['Sound notifications', '提示音'],
                ['Screen notifications', '浏览器通知'],
                ['Join/left notify', '显示加入、退出消息'],
                ['Allow LaTeX', '显示LaTeX公式'],
                ['Allow Highlight', '代码高亮'],
                ['Allow Images', '显示图片'],
                ['Embed images like: ![Title](https://i.imgur.com/image.png)', '发图格式：![标题](图片地址)'],
                ['Allow All Images (Not Recommended)', '关闭图片域名限制（不推荐）'],
                ['Check allow images to set this. If this is not checked, only images hosted at trusted domain names can be rendered. Note that this may enable IP grabbers to grab your IP address.', '勾选显示图片以设置这个选项。如果没有勾选这个选项，只有受信任的域名上的图片会被显示。请注意，勾选这个选项会产生被IP记录器记录IP地址的风险。'],
                ['Soft @Mention', '@中间加空格'],
                ['Record Messages', '客户端记录信息'],
                ['Mobile buttons', '手机版按钮'],
                ['Index Online Count', '首页显示在线人数'],
                ['Language', '语言'],
                ['Color scheme', '配色方案'],
                ['Highlight scheme', '代码高亮方案'],
                ['Connect tunnel', '连接地址'],
                ['Add tunnel', "添加加速隧道"],
                ['Remove tunnel', "移除加速隧道"],
                ['Upload Image', '上传图片'],
                ['Clear all messages', '清空本页聊天记录'],
                ['Set auto color', '设置用户名自动改色'],
                ['Set Massage Template', '设置消息模板'],
                ['Copy JSON Record', '复制JSON记录'],
                ['Copy Message Record', '复制消息记录'],
                ['Don\'t Click', '隐藏功能'],
                ['Users online', '在线列表'],
                ['(Click user to invite; right click to ignore.)', '(点击邀请,右键拉黑)'],
                ['Advance Notice Before Sending Long Messages', '发送长消息自动预警'],
                ['Fold Long Messages', '折叠长消息'],
                ['Send', '发送'],
                ['Settings', '设置'],
            ]),
            prompt: new Map([
                /* Alerts and prompts */
                ['Nickname:', '请输入用户名：'],
                ['Your nickname color:(press enter without inputing to reset; input "random" to set it to random)', '你的用户名颜色：（直接按回车重置，输入“random”设为随机。）'],
                ['Rejoin or join a Channel to make it go into effect.', '重新加入或加入别的频道来使用户名颜色生效。'],
                [/Suessfully set your auto nickname color to #([0-9A-Fa-f]{6})\. /, '成功设置用户名颜色为#$1。'],
                ['Suessfully set your auto nickname color to random. ', '成功设置用户名颜色为随机。'],
                ['Suessfully disabled autocolor.', '成功关闭自动用户名改色。'],
                ['Invalid color. Please give color in hex RGB code.', '非法色值。请使用十六进制RBG色码。'],
                ['Your template string:(use %m to replace your message content. press enter without inputing to reset.)', '你的模板字符串：（用%m代替你的消息内容。直接按下回车来关闭模板。）'],
                ['Suessfully set template.', '成功设置模板。'],
                ['Suessfully disabled template.', '成功关闭模板。'],
                ['Invalid template. ', '非法模板。'],
                [' successfully copied to clipboard. ', '成功复制到剪贴板。'],
                ['JSON log', 'JSON记录'],
                ['Normal log', '普通记录'],
                ['Please save it in case it may be lost.', '请及时保存记录，以免丢失。'],
                ['Failed to copy log to clipboard.', '复制失败。'],
                ['target channel:(defaultly random channel generated by server)', '你要邀请对方到的频道名称：（默认为随机频道）'],
                ['Image host provided by Dataeverything team. All uploads on your own responsibility.', '由Dataeverything Team提供图床。使用该图床做出的所有行为由你自负责任。'],
                ['Warning, please verify this is where you want to go: ', '警告，请确认以下链接是你要去的：'],
                ['Automatically converted into upper case by client.', '客户端已自动转换为大写。'],
                ["Please input the tunnel URL.(IF YOU DON'T KNOW WHAT THIS DOES, CLICK CANCEL.)", "请输入隧道URL.(如果你不知道这是做什么的，请点击取消。)"],
                ["Invaild tunnel URL.", "非法的隧道URL."],
                ["The LaTeX included in your text may cause you got kicked, rejected sending.", "你的文本中含有部分可能导致你被踢出的特殊LaTeX公式，已中断发送。"]
            ]),
            system: new Map([
                /* System messages */
                ['Users online: ', '在线用户：'],
                ['Thanks for using hackchat++ client! Source at: ', '感谢使用hackchat++！开源在：'],
                [/^([a-zA-Z0-9_]{1,24}) joined$/, '$1 加入了聊天室'],
                [/^([a-zA-Z0-9_]{1,24}) left$/, '$1 离开了聊天室'],
                ['You cancelled joining. Press enter at the input field to reconnect.', '你取消了加入。在输入框上按回车可以重新加入。'],
                ['Server disconnected. ', '断连了！'],
                ['Attempting to reconnect. . .', '正在尝试重连……'],
                ['Failed to connect to server. When you think there is chance to succeed in reconnecting, press enter at the input field to reconnect.', '连接到服务器失败。如果你想重连，在输入框上按回车即可。'],
                [/You may be kicked or moved to this channel by force to channel \?(.+) \. Unable to get full user list\. /, '你可能被踢到或者强制移到了频道 ?$1 。无法获取完整用户列表。'],
                [/Unexpected Channel \?(.+) \. You may be kicked or moved to this channel by force\. /, '异常：你现在在频道 ?$1 。你可能被踢出了，或者被强制移动了。'],
                [/Unexpected Channel \?(.+) \. You may be locked out from \?(.+) \. You may also be kicked or moved to this channel by force\. /, '异常：你现在在频道 ?$1 。可能是 ?$2 锁房了，把你屏蔽在了 ?$1 。也可能是你被踢出了，或者被强制移动了。'],
                [/You are now at \?(.+) \. A mod has moved you、. /, '你现在在 ?$1 了。一名管理员移动了你。'],
                ['Please refresh to apply language. Multi language is in test and not perfect yet. ', '请刷新来应用语言设置。多语言支持目前还在测试当中，并不完美。'],
                [/Ignored nick ([a-zA-Z0-9_]{1,24})\./, '已忽略$1的消息。'],
                [/Cancelled ignoring nick ([a-zA-Z0-9_]{1,24})\./, '已取消忽略$1的消息。'],
                [/^Kicked ([a-zA-Z0-9_]{1,24})$/, '已踢出 $1'],
                [/^Banned ([a-zA-Z0-9_]{1,24})$/, '已封禁 $1'],
                ["Sucessfully added tunnel.", "成功增加隧道。"],
                ["Sucessfully removed tunnel.", "成功移除隧道。"],
                ["Sucessfully changed tunnel, refresh to apply the changes.", "成功更改隧道设置，刷新来应用更改。"]
            ]),
            info: new Map([
                /* Chatroom info */
                [/^You whispered to @([a-zA-Z0-9_]{1,24}): /, '你对 @$1 私聊：'],
                [/^([a-zA-Z0-9_]{1,24}) whispered: /, '$1 对你私聊：'],
                [/^You invited ([a-zA-Z0-9_]{1,24}) to /, '你邀请 $1 到 '],
                [/^([a-zA-Z0-9_]{1,24}) invited you to /, '$1 邀请你到 '],
                ['Nickname must consist of up to 24 letters, numbers, and underscores', '用户名只能包含24个及以下字符，只能包含字母、数字和下划线'],
                ['Nickname taken', '该用户名已被占用'],
                ['You have been denied access to that channel and have been moved somewhere else. Retry later or wait for a mod to move you.', '系统阻止了你加入该频道，把你移到了别的地方。请稍后再试或等待管理员移动你。'],
                ['Enter the following to join (case-sensitive):', '请输入以下验证码：（大小写敏感）'],
                ['You are joining channels too fast. Wait a moment and try again.', '你加入得太快了，请稍后再试。'],
                ['You are sending too much text. Wait a moment and try again.\nPress the up arrow key to restore your last message.', '你发送了太多消息。请稍后再试。按方向上键来恢复没发出去的消息。'],
                ['You are changing colors too fast. Wait a moment before trying again.', '你更改颜色太快了，请稍后再试。'],
                ['You are being rate-limited or blocked.', '你被暂时限制了，或者被封禁了。'],
                ['Wait a moment and try again.', '请稍后再试。'],
                [/^([a-zA-Z0-9_]{1,24}) is now ([a-zA-Z0-9_]{1,24})$/, '$1 改名为 $2'],

                /* Help */
                ['All commands:', '全部命令：'],
                ['Category:', '分\ufeff类\ufeff：'],
                ['Name:', '名\ufeff字\ufeff：'],
                ['Admin:', '站\ufeff长\ufeff：'],
                ['Core:', '核\ufeff心\ufeff：'],
                ['Internal:', '内\ufeff部\ufeff：'],
                ['Mod:', '管\ufeff理\ufeff员\ufeff：'],
                ['For specific help on certain commands, use either:\nText: `/help <command name>`\nAPI: `{cmd: \'help\', command: \'<command name>\'}`', '要得到关于特定命令的帮助，你可以使用以下任一方法：\n文字：`/help <命令名字>`\nAPI：`{cmd: \'help\'}, command: \'<命令名字>\'`'],
                ['Unknown command: ', '未知命令：'],
                ['Unknown command', '未知命令'],
                [/ command:$/m, '命令：'],
                ['**Name:**', '**名字：**'],
                ['**Aliases:**', '**别名：**'],
                ['|None|', '|无|'],
                ['|Admin|', '|站长|'],
                ['|Core|', '|核心|'],
                ['|Internal|', '|内部|'],
                ['|Mod|', '|管理员|'],
                ['**Description:**', '**描述：**'],
                ['**Usage:** ', '**用法：**'],
                ['API: ', 'API：'],
                ['Text: ', '文字：'],
                ['**Required Parameters:**', '**参数：**'],
            ]),
            home: new Map([
                /* Frontpage text */
                ['Welcome to hack.chat, a minimal, distraction-free chat application.', '欢迎来到hack.chat，最小化、无干扰的聊天室。'],
                ['You are now experiencing hack.chat with a tweaked client: hackchat\\+\\+. Official hack.chat client is at: https://hack.chat.', '你正在使用一个改版客户端，hackchat++，体验 hack.chat。官方客户端在此：https://hack.chat。'],
                ['Channels are created, joined and shared with the url, create your own channel by changing the text after the question mark. Example: ', '频道是通过网址创建、加入和分享的。通过改变问号后的内容，你就可以创建自己的频道。例如：'],
                ['There are no channel lists *for normal users*, so a secret channel name can be used for private discussions.', '没有*公开给普通人*的频道列表，所以一个秘密的频道名称可以用于私密交流。'],
                ['Here are some pre-made channels you can join: ', '以下是一些你可以加入的公开频道：'],
                ['(Online counts disabled)', '（在线人数显示已关闭）'],
                ['(Getting online counts...)', '（正在获取在线人数……）'],
                [/\((\d+) users online, /, '（当你进入这个首页时，全站在线用户数为$1，'],
                [/(\d+) channels existing when you enter this page\)/, '全站频道数为$1）'],
                ['And here\'s a random one generated just for you: ', '这是一个为你准备的随机频道： '],
                ['Formatting:', '排版：'],
                ['Notice: Dont send raw source code without using a code block!', '注意：不要不带代码块直接发源代码！'],
                ['Surround LaTeX with a dollar sign for inline style $\\zeta(2) = \\pi^2/6$, and two dollars for display. ', '用美元符号包裹行内公式： $\\zeta(2) = \\pi^2/6$，用两个美元符号包裹块级公式。'],
                ['For syntax highlight, wrap the code like: \\`\\`\\`<language> <the code>\\`\\`\\` where <language> is any known programming language.', '像这样包裹代码来获得语法高亮：\\`\\`\\`<编程语言名称> <代码>\\`\\`\\`'],
                ['Current Github: ', '当前Github仓库：'],
                ['Legacy GitHub: ', '旧版Github仓库：'],
                ['Bots, Android clients, desktop clients, browser extensions, docker images, programming libraries, server modules and more:', '机器人，安卓客户端，桌面客户端，浏览器扩展，Docker映像，编程库，服务端模块和更多：'],
                ['Server and web client released under the WTFPL and MIT open source license.', '服务端和网页客户端分别采用WTFPL和MIT协议开源。'],
                ['No message history is retained on the hack.chat server, but in certain channels there may be bots made by users which record messages.', '没有聊天记录保存在hack.chat服务器上，但是在某些频道，可能有用户做的机器人保存聊天记录。'],
                ['Github of hackchat++ (aka hackchat-client-plus): ', 'hackchat++（又名hackchat client plus）的Github：'],
                ['Hosted at https://hcer.netlify.app/ and https://hc.thz.cool/ (thanks to Maggie, aka THZ, for hosting).', '托管在 https://hcer.netlify.app/ 和 https://hc.thz.cool/（感谢Maggie，即THZ，提供托管）。'],
                ['Links: ', '友情链接：'],
                [' (Thanks for providing replying script!) ', '（感谢提供回复功能的代码）']
            ]),
        }
    ]
])

function i18ntranslate(text, rules = ['all']) {
    if (lang == 'en-US' || !i18n.has(lang)) return text
    if (rules == ['all']) {
        for (let rule in i18n.get(lang)) {
            for (let item of i18n.get(lang)[rule]) {
                text = text.replace(item[0], item[1])
            }
        }
    } else if (typeof rules == 'string') {
        for (let item of i18n.get(lang)[rules]) {
            text = text.replace(item[0], item[1])
        }
    } else if (Array.isArray(rules)) {
        for (let rule of rules) {
            for (let item of i18n.get(lang)[rule]) {
                text = text.replace(item[0], item[1])
            }
        }
    }
    return text
}

let lang = 'zh-CN'

if (localStorageGet('i18n') && localStorageGet('i18n') != 'zh-CN') {
    if (i18n.has(localStorageGet('i18n'))) {
        lang = localStorageGet('i18n')
        document.querySelector('html').lang = lang
        document.querySelectorAll('[tr]').forEach((el) => {
            if (el.tagName == 'button') debugger
            el.innerHTML = i18ntranslate(el.innerHTML, 'ui')
        })
    } else {
        alert(`Sorry, we have not made language ${localStorageGet('i18n')}. You can try: en-US.`)
    }
}

$id('sidebar').onmouseenter = $id('sidebar').onclick = function (e) {
	if (e.target == $id('sidebar-close')) {
		return
	}
	$id('sidebar-content').classList.remove('hidden');
	$id('sidebar').classList.add('expand');
	e.stopPropagation();
}

$id('sidebar').onmouseleave = document.ontouchstart = function (event) {
	var e = event.toElement || event.relatedTarget;
	try {
		if (e.parentNode == this || e == this) {
			return;
		}
	} catch (e) { return; }

	if (!$id('pin-sidebar').checked) {
		$id('sidebar-content').classList.add('hidden');
		$id('sidebar').classList.remove('expand');
	}
}

$id('sidebar-close').onclick = function () {
	if (!$id('pin-sidebar').checked) {
		$id('sidebar-content').classList.add('hidden');
		$id('sidebar').classList.remove('expand');
	}
}

/* ---Sidebar buttons--- */

$id('clear-messages').onclick = function () {
	// Delete children elements
	var messages = $id('messages');
	messages.innerHTML = '';
}

$id('set-custom-color').onclick = function () {
	// Set auto changecolor
	let color = prompt(i18ntranslate('Your nickname color:(leave blank to reset; input "random" to set it to random color)', 'prompt'))
	if (color == null) {
		return;
	}
	if (color == 'random') {
		myColor = 'random';
		pushMessage({ nick: '*', text: "Suessfully set your auto nickname color to random. Rejoin or join a Channel to make it go into effect." })
	} else if (/(#?)((^[0-9A-F]{6}$)|(^[0-9A-F]{3}$))/i.test(color)) {
		myColor = color.replace(/#/, '');
		pushMessage({ nick: '*', text: `Suessfully set your auto nickname color to #${myColor}. Rejoin or join a Channel to make it go into effect.` })
	} else if (color == '') {
		myColor = null;
		pushMessage({ nick: '*', text: "Successfully disabled autocolor." })
	} else {
		pushMessage({ nick: '!', text: "Invalid color. Please set color in hex code." })
	}
	localStorageSet('my-color', myColor || '')//if myColor is null, set an empty string so that when it is got it will be ('' || null) (confer {var myColor = localStorageGet('my-color') || null;} at about line 190) the value of which is null
}

$id('set-template').onclick = function () {
	// Set template
	let template = prompt(i18ntranslate('Your template string:(use %m to replace your message content. press enter without inputing to reset.)', 'prompt'))
	if (template == null) {
		return;
	}
	if (template.indexOf('%m') > -1) {
		const rand = String(Math.random()).slice(2)
		templateStr = template
			.replace(/\\\\/g, rand)
			.replace(/\\n/g, '\n')
			.replace(/\\t/g, '\t')
			.replace(new RegExp(rand, 'g'), '\\\\')
		pushMessage({ nick: '*', text: "Suessfully set template." })
	} else if (template == '') {
		templateStr = null;
		pushMessage({ nick: '*', text: "Suessfully disabled template." })
	} else {
		pushMessage({ nick: '!', text: "Invalid template. " })
	}
	localStorageSet('my-template', templateStr || '')
}

$id('export-json').onclick = function () {
	navigator.clipboard.writeText(jsonLog).then(function () {
		pushMessage({ nick: '*', text: "JSON log successfully copied to clipboard. Please save it in case it may be lost." })
	}, function () {
		pushMessage({ nick: '!', text: "Failed to copy log to clipboard." })
	});
}

$id('export-readable').onclick = function () {
	navigator.clipboard.writeText(readableLog).then(function () {
		pushMessage({ nick: '*', text: "Normal log successfully copied to clipboard. Please save it in case it may be lost." })
	}, function () {
		pushMessage({ nick: '!', text: "Failed to copy log to clipboard." })
	});
}
$id('add-tunnel').onclick = function () {
	let tunneladdr = prompt(i18ntranslate("Please input the tunnel URL.(IF YOU DON'T KNOW WHAT THIS DOES, CLICK CANCEL.)", "prompt"));
	if (!tunneladdr) {
		return;
	}
	if (tunneladdr.indexOf('ws') == -1) {
		alert(i18ntranslate("Invaild tunnel URL.", "prompt"))
		return;
	}
	tunnels.push(tunneladdr);
	localStorageSet('tunnels', JSON.stringify(tunnels))
	pushMessage({ nick: '*', text: "Sucessfully added tunnel." })
}

$id('remove-tunnel').onclick = function () {
	let tunneladdr = prompt(i18ntranslate("Please input the tunnel URL.(IF YOU DON'T KNOW WHAT THIS DOES, CLICK CANCEL.)", "prompt"));
	if (!tunneladdr) {
		return;
	}
	if (tunnels.indexOf(tunneladdr) == -1) {
		alert(i18ntranslate("Invaild tunnel URL.", "prompt"))
		return;
	}
	tunnels.splice(tunnels.indexOf(tunneladdr), 1);
	localStorageSet('tunnels', JSON.stringify(tunnels))
	pushMessage({ nick: '*', text: "Sucessfully removed tunnel." })
}

$("#tunnel-selector").onchange = function (e) {
	localStorageSet("current-tunnel", e.target.value)
	pushMessage({ nick: "*", text: "Sucessfully changed tunnel, refresh to apply the changes." })
}

// $id('img-upload').onclick = function () {
// 	if (localStorageGet('image-upload') != 'true') {
// 		confirmed = confirm(i18ntranslate('Image host provided by DataEverything team. All uploads on your own responsibility.', prompt))
// 		if (confirmed) {
// 			localStorageSet('image-upload', true)
// 		} else {
// 			return
// 		}
// 	}
// 	window.open('https://img.thz.cool/upload', 'newwindow', 'height=512, width=256, top=50%,left=50%, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no')
// }

/* ---Sidebar settings--- */

function registerSetting(name, default_ = false, callback = null, on_register = null) {
	let checkbox = document.getElementById(name)
	let enabled = default_ ? localStorageGet(name) != 'false' : localStorageGet(name) == 'true'
	checkbox.checked = enabled
	checkbox.onchange = function (e) {
		localStorageSet(name, !!e.target.checked)
		if (typeof callback == 'function') {
			callback(!!e.target.checked)
		}
	}
	if (typeof on_register == 'function') {
		on_register(enabled)
	} else if (on_register == true || on_register == null && typeof callback == 'function') {
		callback(enabled)
	}
	return enabled
}

// Restore settings from localStorage

registerSetting('pin-sidebar', false, null, (enabled) => {
	if (enabled) {
		$id('sidebar-content').classList.remove('hidden');
	}
})

registerSetting('joined-left', true)

registerSetting('parse-latex', true, (enabled) => {
	if (enabled) {
		md.inline.ruler.enable(['katex']);
		md.block.ruler.enable(['katex']);
	} else {
		md.inline.ruler.disable(['katex']);
		md.block.ruler.disable(['katex']);
	}
}, true)

registerSetting('syntax-highlight', true, (enabled) => {
	markdownOptions.doHighlight = enabled
}, true)

registerSetting('allow-imgur', true, (enabled) => {
	allowImages = enabled
	$id('allow-all-images').disabled = !enabled
}, true)

registerSetting('allow-all-images', false, (enabled) => {
	whitelistDisabled = enabled
}, true)

registerSetting('soft-mention', false)

registerSetting('auto-precaution', false)

var auto_fold, do_log_messages, should_get_info

registerSetting('auto-fold', false, (enabled) => {
	auto_fold = enabled
}, true)

registerSetting('message-log', false, (enabled) => {
	do_log_messages = enabled
}, true)

toggleLog()

function toggleLog() {
	let _ = do_log_messages ? '[log enabled]' : '[log disabled]'
	jsonLog += _;
	readableLog += '\n' + _;
}

registerSetting('mobile-btn', false, (enabled) => {
	if (enabled) {
		$id('mobile-btns').classList.remove('hidden');
		$id('more-mobile-btns').classList.remove('hidden');
	} else {
		$id('mobile-btns').classList.add('hidden');
		$id('more-mobile-btns').classList.add('hidden');
	}
	updateInputSize()
}, true)

registerSetting('should-get-info', false, (enabled) => {
	should_get_info = enabled
}, true)

/* ---Buttons for some mobile users--- */

function createMobileButton(text, callback, id) {
	id = id ?? text.toLowerCase()
	let container = $id('more-mobile-btns')
	let button = document.createElement('button')
	button.type = 'button'
	button.classList.add('char')
	button.textContent = text
	button.onclick = typeof callback == 'function' ? callback : (
		typeof callback == 'string' ? () => insertAtCursor(callback) : () => insertAtCursor(text)
	)
	container.appendChild(button)
}

function initiateMobileButtons() {
	createMobileButton('Tab', keyActions.tab, 'mob-btn-tab')

	createMobileButton('/', '/', 'mob-btn-slash')

	createMobileButton('↑', () => {
		if (lastSentPos < lastSent.length - 1) {
			keyActions.up()
		}
	}, 'mob-btn-pre')

	createMobileButton('↓', () => {
		if (lastSentPos > 0) {
			keyActions.down()
		}
	}, 'mob-btn-next')

	createMobileButton('@', '@', 'mob-btn-at')

	createMobileButton('\\n', '\n', 'mob-btn-newline')

	createMobileButton('?', '?', 'mob-btn-question')

	createMobileButton('*', '*', 'mob-btn-astrisk')

	createMobileButton('#', '#', 'mob-btn-hash')

	createMobileButton('`', '`', 'mob-btn-backquote')
}

function clearMobileButtons() {
	$id('more-mobile-btns').innerHTML = ''
}

initiateMobileButtons()

$('#send').onclick = function () {
	keyActions.send()
}

/* ---Sidebar user list--- */

// User list
var onlineUsers = []
var ignoredUsers = []
var ignoredHashs = []
var usersInfo = {};

function userAdd(nick, user_info) {

	var user = document.createElement('a');
	user.textContent = nick;

	user.onclick = function (e) {
		userInvite(nick)
	}

	user.oncontextmenu = function (e) {
		e.preventDefault()
		if (ignoredUsers.indexOf(nick) > -1) {
			userDeignore(nick)
			pushMessage({ nick: '*', text: `Cancelled ignoring nick ${nick}.` })
		} else {
			userIgnore(nick)
			pushMessage({ nick: '*', text: `Ignored nick ${nick}.` })
		}
	}
	if(user_info){
	user.onmouseenter = function (e) {
		user.classList.add('nick')
		addClassToMessage(user.parentElement, user_info)
		addClassToNick(user, user_info)
	}}

	user.onmouseleave = function (e) {
		user.style.removeProperty('color')
		user.className = ''
	}

	var userLi = document.createElement('li');
	userLi.appendChild(user);
	if (user_info){
		if (user_info.hash) {
			userLi.title = user_info.hash
		}
	}

	userLi.id = `user-li-${nick}`
	if (user_info){
		if (user_info.trip) {
			let tripEl = document.createElement('span')
			tripEl.textContent = ' ' + trip
			tripEl.classList.add('trip')
			userLi.appendChild(tripEl)
		}
	}

	$id('users').appendChild(userLi);
	onlineUsers.push(nick);
	if(user_info){
		usersInfo[nick] = user_info
	}else{
		usersInfo[nick] = {'nick':nick}
	}
}

function userRemove(nick, user_info) {
	var users = $id('users');
	var children = users.children;

	users.removeChild(document.getElementById(`user-li-${nick}`))

	var index = onlineUsers.indexOf(nick);
	if (index >= 0) {
		onlineUsers.splice(index, 1);
	}

	delete usersInfo[nick]
}

function userUpdate(args) {
	usersInfo[args.nick] = {
		...usersInfo[args.nick],
		...args
	}

	let user_info = usersInfo[args.nick]

	let user = document.getElementById(`user-li-${args.nick}`).firstChild

	user.onmouseenter = function (e) {
		user.classList.add('nick')
		addClassToMessage(user.parentElement, user_info)
		addClassToNick(user, user_info)
	}
}

function usersClear() {
	var users = $id('users');

	while (users.firstChild) {
		users.removeChild(users.firstChild);
	}

	onlineUsers.length = 0;
}

function userInvite(nick) {
	let target = prompt(i18ntranslate('target channel:(defaultly random channel generated by server)', 'prompt'))
	if (target) {
		send({ cmd: 'invite', nick: nick, to: target });
	} else {
		if (target == '') {
			send({ cmd: 'invite', nick: nick });
		}
	}
}

function userIgnore(nick) {
	ignoredUsers.push(nick)
}

function userDeignore(nick) {
	ignoredUsers.splice(ignoredUsers.indexOf(nick))
}

function hashIgnore(hash) {
	ignoredHashs.push(hash)
}

function hashDeignore(hash) {
	ignoredHashs.splice(ignoredHashs.indexOf(hash))
}


/* ---Sidebar switchers--- */

/* color scheme switcher */

var schemes = [
	'黑色系 - 寒夜',
	'android',
	'android-white',
	'atelier-dune',
	'atelier-forest',
	'atelier-heath',
	'atelier-lakeside',
	'atelier-seaside',
	'banana',
	'bright',
	'bubblegum',
	'chalk',
	'default',
	'eighties',
	'fresh-green',
	'greenscreen',
	'hacker',
	'maniac',
	'mariana',
	'military',
	'mocha',
	'monokai',
	'nese',
	'ocean',
	'ocean-OLED',
	'omega',
	'pop',
	'railscasts',
	'solarized',
	'tk-night',
	'tomorrow',
	'carrot',
	'lax',
	'Ubuntu',
	'gruvbox-light',
	'fried-egg',
	'rainbow',
	'turbid-jade',
	'old-paper',
	'chemistory-blue',
	// 'crosst-chat-night',
	// 'crosst-chat-city',
	'backrooms-liminal',
	'amoled',
];

var highlights = [
	'agate',
	'androidstudio',
	'atom-one-dark',
	'darcula',
	'github',
	'rainbow',
	'tk-night',
	'tomorrow',
	'xcode',
	'zenburn'
]

var languages = [
	['English', 'en-US'],
	['简体中文', 'zh-CN']
]

var currentScheme = '黑色系 - 寒夜';
var currentHighlight = 'rainbow';

function setScheme(scheme) {
	currentScheme = scheme;
	$id('scheme-link').href = "schemes/" + scheme + ".css";
	localStorageSet('scheme', scheme);
}

function setHighlight(scheme) {
	currentHighlight = scheme;
	$id('highlight-link').href = "vendor/hljs/styles/" + scheme + ".min.css";
	localStorageSet('highlight', scheme);
}

function setLanguage(language) {
	lang = language
	localStorageSet('i18n', lang);
	pushMessage({ nick: '!', text: 'Please refresh to apply language. Multi language is in test and not perfect yet. ' }, { i18n: true })
}
// load tunnels
var tunnels = localStorageGet('tunnels');
if (tunnels) {
	tunnels = JSON.parse(tunnels);
} else {
	tunnels = ["wss://ws.crosst.chat/"]
	localStorageSet('tunnels', JSON.stringify(tunnels))
}
var currentTunnel = localStorageGet("current-tunnel");
var ws_url
if (currentTunnel) {
	ws_url = currentTunnel
} else {
	localStorageSet("current-tunnel", "wss://ws.crosst.chat/")
	ws_url = "wss://ws.crosst.chat/"
}

// Add tunnels options to tunnels selector
tunnels.forEach(function (tunnelurl) {
	var tunnel = document.createElement("option");
	var link = document.createElement("a");
	link.setAttribute("href", tunnelurl);
	tunnel.textContent = link.hostname
	tunnel.value = tunnelurl
	$id('tunnel-selector').appendChild(tunnel)
})
// Add scheme options to dropdown selector
schemes.forEach(function (scheme) {
	var option = document.createElement('option');
	option.textContent = scheme;
	option.value = scheme;
	$id('scheme-selector').appendChild(option);
});

highlights.forEach(function (scheme) {
	var option = document.createElement('option');
	option.textContent = scheme;
	option.value = scheme;
	$id('highlight-selector').appendChild(option);
});

languages.forEach(function (item) {
	var option = document.createElement('option');
	option.textContent = item[0];
	option.value = item[1];
	$id('i18n-selector').appendChild(option);
});


$id('scheme-selector').onchange = function (e) {
	setScheme(e.target.value);
}

$id('highlight-selector').onchange = function (e) {
	setHighlight(e.target.value);
}

$id('i18n-selector').onchange = function (e) {
	setLanguage(e.target.value)
}

// Load sidebar configaration values from local storage if available
if (localStorageGet('scheme')) {
	setScheme(localStorageGet('scheme'));
}

let ctunnel

if (localStorageGet('highlight')) {
	setHighlight(localStorageGet('highlight'));
}

if (localStorageGet('current-tunnel')) {
	ctunnel = localStorageGet('current-tunnel')
} else {
	ctunnel = "wss://ws.crosst.chat/"
}

$id('scheme-selector').value = currentScheme;
$id('highlight-selector').value = currentHighlight;
$id('i18n-selector').value = lang;
$("#tunnel-selector").value = ctunnel;

/*
 *
 * NOTE: The client side of hack.chat is currently in development,
 * a new, more modern but still minimal version will be released
 * soon. As a result of this, the current code has been deprecated
 * and will not actively be updated.
 *
*/

/**
 * Stores active messages
 * These are messages that can be edited.
 * @type {{ customId: string, userid: number, sent: number, text: string, elem: HTMLElement }[]}
 */
var checkActiveCacheInterval = 30 * 1000;
var activeMessages = [];
var users_ = []


function nickGetHash(nick) {
	for (let k in users_) {
		if (users_[k].nick === nick) return users_[k].hash
	}
}

setInterval(function () {
	var editTimeout = 6 * 60 * 1000;
	var now = Date.now();
	for (var i = 0; i < activeMessages.length; i++) {
		if (now - activeMessages[i].sent > editTimeout) {
			activeMessages.splice(i, 1);
			i--;
		}
	}
}, checkActiveCacheInterval);

function addActiveMessage(customId, userid, text, elem) {
	activeMessages.push({
		customId,
		userid,
		sent: Date.now(),
		text,
		elem,
	});
}

/* ---Websocket stuffs--- */

var ws;

var wasConnected = false;

var isInChannel = false;
var purgatory = false;

var shouldAutoReconnect = true;

var isAnsweringCaptcha = false;

function join(channel, oldNick) {
	try {
		ws.close()
	} catch (e) { }

	ws = new WebSocket(ws_url);

	wasConnected = false;

	ws.onopen = function () {
		hook.run("before","connect",[])
		var shouldConnect = true;
		if (!wasConnected) {
			if (location.hash) {
				myNick = location.hash.slice(1);
			} else if (typeof oldNick == 'string') {
				if (verifyNickname(oldNick.split('#')[0])) {
					myNick = oldNick;
				}
			} else {
				var newNick = prompt(i18ntranslate('Nickname:', 'prompt'), myNick);
				if (newNick !== null) {
					myNick = newNick;
				} else {
					// The user cancelled the prompt in some manner
					shouldConnect = false;
					shouldAutoReconnect = false;
					pushMessage({ nick: '!', text: "You cancelled joining. Press enter at the input field to reconnect." })
				}
			}
		}

		if (myNick && shouldConnect) {
			localStorageSet('my-nick', myNick);
			var myPassword = null;
			if(myNick.indexOf("#") != -1){
				myPassword=myNick.split("#").slice(1).join("#")
				myNick=myNick.split("#")[0]
			}
			if(myPassword){
				send({ cmd: 'join', channel: channel, nick: myNick, password: myPassword, clientName:"[CrosSt++](https://csc.thz.cool/)" });
			}else{
			send({ cmd: 'join', channel: channel, nick: myNick, clientName:"[CrosSt++](https://csc.thz.cool/)" });}
			wasConnected = true;
			shouldAutoReconnect = true;
		} else {
			ws.close()
		}

	}

	ws.onclose = function () {
		hook.run("after","disconnected",[])
		isInChannel = false

		if (shouldAutoReconnect) {
			if (wasConnected) {
				wasConnected = false;
				pushMessage({ nick: '!', text: "Server disconnected. Attempting to reconnect. . ." });
			}

			window.setTimeout(function () {
				if (myNick.split('#')[1]) {
					join(channel, (myNick.split('#')[0] + '_').replace(/_{3,}$/g, '') + '#' + myNick.split('#')[1]);
				} else {
					join(channel, (myNick + '_').replace(/_{3,}$/g, ''));
				}
			}, 2000);

			window.setTimeout(function () {
				if (!wasConnected) {
					shouldAutoReconnect = false;
					pushMessage({ nick: '!', text: "Failed to connect to server. When you think there is chance to succeed in reconnecting, press enter at the input field to reconnect." })
				}
			}, 2000);
		}
	}

	ws.onmessage = function (message) {
		var args = JSON.parse(message.data);
		var cmd = args.cmd;
		var command = COMMANDS[cmd];
		var data = hook.run("in","recv",[args,cmd,command])
		if(!data){
			return
		}else{
			args=data[0]
			cmd=data[1]
			command=data[2]
		}
		if (args.channel) {
			if (args.channel != myChannel && isInChannel) {
				isInChannel = false
				if (args.channel != 'purgatory') {
					purgatory = false
					usersClear()
					let p = document.createElement('p')
					p.textContent = `You may be kicked or moved to this channel by force to channel ?${args.channel}. Unable to get full user list. `
					$id('users').appendChild(p)
					pushMessage({ nick: '!', text: `Unexpected Channel ?${args.channel} . You may be kicked or moved to this channel by force. ` })
				} else {
					purgatory = true
					pushMessage({ nick: '!', text: `Unexpected Channel ?${args.channel} . You may be locked out from ?${myChannel} . You may also be kicked or moved to this channel by force. ` })
				}
			} else if (isInChannel) {
				if (purgatory && myChannel != 'purgatory') {// you are moved by a mod from purgatory to where you want to be at
					purgatory = false
					pushMessage({ nick: '!', text: `You are now at ?${args.channel} . A mod has moved you. ` })
				} else if (args.channel == 'purgatory') {
					purgatory = true
				}
			}
		}
		if (cmd == 'join') {
			let limiter = seconds['join']
			let time = (new Date()).getTime()
			limiter.times.push(time - limiter.last)
			limiter.last = time
			let sum = 0
			let count = 0
			for (let d = 1; d <= limiter.times.length; d++) {
				sum += limiter.times[limiter.times.length - d]
				if (sum > 1000) {
					count = d
					break
				}
			}
			limiter.times = limiter.times.slice(-count)
			if (localStorageGet('joined-left') != 'false') {
				if (count > 5 && $id('joined-left').checked) {
					$id('joined-left').checked = false // temporarily disable join/left notice
					pushMessage({ nick: '*', text: 'Frequent joining detected. Now temporarily disabling join/left notice.' })
				} else if (count < 5 && !($id('joined-left').checked)) {
					$id('joined-left').checked = true
					pushMessage({ nick: '*', text: 'Auto enabled join/left notice.' })
				}
			}
		}
		if (command) {
			command.call(null, args, message.data);
		}
		if (do_log_messages) { jsonLog += ';' + message.data }
	}
}

var COMMANDS = {
	chat: function (args, raw) {
		if (ignoredUsers.indexOf(args.nick) >= 0 || ignoredHashs.indexOf(nickGetHash(args.nick)) >= 0) {
			return
		}
		var elem = pushMessage(args, { i18n: false, raw })

		if (typeof (args.customId) === 'string') {
			addActiveMessage(args.customId, args.userid, args.text, elem)
		}
	},

	updateMessage: function (args) {
		var customId = args.customId;
		var mode = args.mode;

		if (!mode) {
			return;
		}

		var message;
		for (var i = 0; i < activeMessages.length; i++) {
			var msg = activeMessages[i];
			if (msg.userid === args.userid && msg.customId === customId) {
				message = msg;
				break;
			}
		}

		if (!message) {
			return;
		}

		var textElem = message.elem.querySelector('.text');
		if (!textElem) {
			return;
		}

		var newText = message.text;
		if (mode === 'overwrite') {
			newText = args.text;
		} else if (mode === 'append') {
			newText += args.text;
		} else if (mode === 'prepend') {
			newText = args.text + newText;
		}

		message.text = newText;

		// Scroll to bottom if necessary
		var atBottom = isAtBottom();

		if (verifyMessage({ text: newText })) {
			textElem.innerHTML = md.render(newText);
		} else {
			let pEl = document.createElement('p')
			pEl.appendChild(document.createTextNode(newText))
			pEl.classList.add('break') //make lines broken at newline characters, as this text is not rendered and may contain raw newline characters
			textElem.appendChild(pEl)
			console.log('norender to dangerous message:', args)
		}

		if (atBottom) {
			window.scrollTo(0, document.body.scrollHeight);
		}
	},

	info: function (args, raw) {
		if ((args.type == 'whisper' || args.type == 'invite') && (ignoredUsers.indexOf(args.from) >= 0 || ignoredHashs.indexOf(nickGetHash(args.from)) >= 0)) {
			return
		}
		args.nick = '*'
		pushMessage(args, { i18n: true, raw })
	},

	emote: function (args, raw) {
		if (ignoredUsers.indexOf(args.text.match(/@(.+?)(?: .+)/)[1]) >= 0 || ignoredHashs.indexOf(nickGetHash(args.text.match(/@(.+?)(?: .+)/)[1])) >= 0) {
			return
		}
		args.nick = '*'
		pushMessage(args, { i18n: false, raw })
	},

	warn: function (args, raw) {
		args.nick = '!';
		pushMessage(args, { i18n: true, raw });
	},

	onlineSet: function (args, raw) {
		isAnsweringCaptcha = false
		args.nicks.push(myNick.split('#')[0])
		let users = args.nicks;
		let nicks = args.nicks;
		users_ = args.nicks

		usersClear();

		users.forEach(function (user) {
			userAdd(user.nick, null);
		});

		let nicksHTML = nicks.map(function (nick) {
			if (nick.match(/^_+$/)) {
				return nick // such nicknames made up of only underlines will be rendered into a horizontal rule.
			}
			let div = document.createElement('div')
			div.innerHTML = md.render(nick)
			return div.firstChild.innerHTML
		})

		// respectively render markdown for every nickname in order to prevent the underlines in different nicknames from being rendered as italics or bold for matching markdown syntax.
		pushMessage({ nick: '*', text: i18ntranslate("Users online: ", 'system') + nicksHTML.join(", ") }, { i18n: false, isHtml: true, raw })

		pushMessage({ nick: '*', text: "Thanks for using crosstchat++ client! It's in beta, so bugs are possible, report to 0x24a!" }, { i18n: true })

		if (myColor) {
			if (myColor == 'random') {
				myColor = Math.floor(Math.random() * 0xffffff).toString(16).padEnd(6, "0")
			}
			send({ cmd: 'changecolor', color: myColor })
		}

		isInChannel = true
	},

	onlineAdd: function (args, raw) {
		var nick = args.nick;
		users_.push(args)

		userAdd(nick, args);

		if ($id('joined-left').checked) {
			let payLoad = { nick: '*', text: nick + " joined" }

			//onlineAdd can contain trip but onlineRemove doesnt contain trip
			if (args.trip) {
				payLoad.trip = args.trip
			}
			pushMessage(payLoad, { i18n: true, raw });
		}
	},

	onlineRemove: function (args, raw) {
		var nick = args.nick;
		users_ = users_.filter(function (item) {
			return item.nick !== args.nick;
		});

		userRemove(nick);

		if ($id('joined-left').checked) {
			pushMessage({ nick: '*', text: nick + " left" }, { i18n: true, raw });
		}
	},

	updateUser: function (args) {
		userUpdate(args)
	},

	captcha: function (args) {
		isAnsweringCaptcha = true

		const NS = 'http://www.w3.org/2000/svg'

		let messageEl = document.createElement('div');
		messageEl.classList.add('message', 'info');


		let nickSpanEl = document.createElement('span');
		nickSpanEl.classList.add('nick');
		messageEl.appendChild(nickSpanEl);

		let nickLinkEl = document.createElement('a');
		nickLinkEl.textContent = '#';
		nickSpanEl.appendChild(nickLinkEl);

		let pEl = document.createElement('p')
		pEl.classList.add('text')

		let lines = args.text.split(/\n/g)

		// Core principle: In SVG text can be smaller than 12px even in Chrome.
		let svgEl = document.createElementNS(NS, 'svg')
		svgEl.setAttribute('white-space', 'pre')
		svgEl.style.backgroundColor = '#4e4e4e'
		svgEl.style.width = '100%'

		// In order to make 40em work right.
		svgEl.style.fontSize = `${$id('messages').clientWidth / lines[0].length * 1.5}px`
		// Captcha text is about 41 lines.
		svgEl.style.height = '41em'

		// I have tried `white-space: pre` but it didn't work, so I write each line in individual text tags.
		for (let i = 0; i < lines.length; i++) {
			let line = lines[i]
			let textEl = document.createElementNS(NS, 'text')
			textEl.innerHTML = line

			// In order to make it in the right position.
			textEl.setAttribute('y', `${i + 1}em`)

			// Captcha text shouldn't overflow #messages element, so I divide the width of the messages container with the overvalued length of each line in order to get an undervalued max width of each character, and than multiply it by 2 (The overvalued aspect ratio of a character) because the font-size attribute means the height of a character.
			textEl.setAttribute('font-size', `${$id('messages').clientWidth / lines[0].length * 1.5}px`)
			textEl.setAttribute('fill', 'white')

			// Preserve spaces.
			textEl.style.whiteSpace = 'pre'

			svgEl.appendChild(textEl)
		}

		pEl.appendChild(svgEl)

		messageEl.appendChild(pEl);
		$id('messages').appendChild(messageEl);

		window.scrollTo(0, document.body.scrollHeight);
	}
}

function addClassToMessage(element, args) {
	if (verifyNickname(myNick.split('#')[0]) && args.nick == myNick.split('#')[0]) {
		element.classList.add('me');
	} else if (args.nick == '!') {
		element.classList.add('warn');
	} else if (args.nick == '*') {
		element.classList.add('info');
	} else if (args.admin) {
		element.classList.add('admin');
	} else if (args.mod) {
		element.classList.add('mod');
	} else {
		return false
	}
	return true
}

function addClassToNick(element, args) {
	if (args.nick === 'jeb_') {
		element.setAttribute("class", "jebbed");
	} else if (args.color && /(^[0-9A-F]{6}$)|(^[0-9A-F]{3}$)/i.test(args.color)) {
		element.setAttribute('style', 'color:#' + args.color + ' !important');
	}
}

function makeTripEl(args, options, date) {
	var tripEl = document.createElement('span');

	if (args.mod) {
		tripEl.textContent = String.fromCodePoint(11088) + " " + args.trip + " ";
	} else {
		tripEl.textContent = args.trip + " ";
	}

	tripEl.classList.add('trip');
	return tripEl
}

function makeNickEl(args, options, date) {
	var nickLinkEl = document.createElement('a');
	nickLinkEl.textContent = args.nick;

	addClassToNick(nickLinkEl, args)

	//tweaked code from crosst.chat
	nickLinkEl.onclick = function () {
		// @TODO Finish right-click menu
		// Reply to a whisper or info is meaningless
		if (args.type == 'whisper' || args.nick == '*' || args.nick == '!') {
			insertAtCursor(args.text);
			$id('chat-input').focus();
			return;
		} else if (args.nick == myNick.split('#')[0]) {
			reply(args)
		} else {
			var nick = args.nick
			let at = '@'
			if ($id('soft-mention').checked) { at += ' ' }
			insertAtCursor(at + nick + ' ');
			input.focus();
			return;
		}
	}
	// Mention someone when right-clicking
	nickLinkEl.oncontextmenu = function (e) {
		e.preventDefault();
		reply(args)
	}

	nickLinkEl.title = date.toLocaleString();

	if (args.color) {
		nickLinkEl.title = nickLinkEl.title + ' #' + args.color
	}

	return nickLinkEl
}

function makeTextEl(args, options, date) {

	let isHtml = options.isHtml ?? false // This is only for better controll to rendering. There are no backdoors to push HTML to users in my repo.
	let raw = options.raw ?? false
	let noFold = options.noFold ?? false

	var textEl = document.createElement('p');
	textEl.classList.add('text');

	let folded = auto_fold && checkLong(args.text) && !noFold

	if (isHtml) {
		textEl.innerHTML = args.text;
	} else if (verifyMessage(args)) {
		textEl.innerHTML = md.render(args.text);
	} else {
		let pEl = document.createElement('p')
		pEl.appendChild(document.createTextNode(args.text))
		pEl.classList.add('break') //make lines broken at newline characters, as this text is not rendered and may contain raw newline characters
		textEl.appendChild(pEl)
		console.log('norender to dangerous message:', args)
	}

	if (folded) {
		textEl.classList.add('folded')
		textEl.onclick = function (e) {
			e.preventDefault()
			if (textEl.classList.contains('folded')) {
				textEl.classList.remove('folded')
			} else {
				textEl.classList.add('folded')
			}
		}
	}

	if (raw) {
		textEl.dataset.raw = raw
		textEl.dataset.displayingRaw = 'false'
		textEl.oncontextmenu = function (e) {
			if (!devMode) {
				return
			}
			e.preventDefault()
			if (textEl.dataset.displayingRaw == 'true') {
				textEl.lastElementChild.remove()
				textEl.dataset.displayingRaw = 'false'
				textEl.onmouseleave = null
			} else {
				let pEl = document.createElement('p')
				pEl.innerHTML = md.render('```json\n' + raw + '\n```')
				textEl.appendChild(pEl)
				textEl.dataset.displayingRaw = 'true'
				textEl.onmouseleave = function (e) {
					textEl.lastElementChild.remove()
					textEl.dataset.displayingRaw = 'false'
					textEl.onmouseleave = null
				}
			}
			if (isAtBottom() && myChannel/*Frontpage should not be scrolled*/) {
				window.scrollTo(0, document.body.scrollHeight)
			}
		}
	}

	// Optimize CSS of code blocks which have no specified language name: add a hjls class for them
	textEl.querySelectorAll('pre > code').forEach((element) => {
		let doElementHasClass = false
		element.classList.forEach((cls) => {
			if (cls.startsWith('language-') || cls == 'hljs') {
				doElementHasClass = true
			}
		})
		if (!doElementHasClass) {
			element.classList.add('hljs')
		}
	})

	return textEl
}


function pushMessage(args, options = {}) {
	args = hook.run("before","pushmessage",args)
	if (!args){
		return //prevented
	}
	let i18n = options.i18n ?? true
	if (i18n && args.text) {
		args.text = i18ntranslate(args.text, ['system', 'info'])
	}

	// Message container
	var messageEl = document.createElement('div');
	

	if (
		typeof (myNick) === 'string' && (
			args.text.match(new RegExp('@' + myNick.split('#')[0] + '\\b', "gi")) ||
			((args.type === "whisper" || args.type === "invite") && args.from)
		)
	) {
		notify(args);
	}

	messageEl.classList.add('message');
	
	var date = new Date(args.time || Date.now());

	addClassToMessage(messageEl, args)

	// Nickname
	var nickSpanEl = document.createElement('span');
	nickSpanEl.classList.add('nick');
	nickSpanEl.classList.add('chat-nick');
	messageEl.appendChild(nickSpanEl);

	if (args.trip) {
		nickSpanEl.appendChild(makeTripEl(args, options, date));
	}

	if (args.nick) {
		nickSpanEl.appendChild(makeNickEl(args, options, date));
	}

	// Text

	messageEl.appendChild(makeTextEl(args, options, date));

	// Scroll to bottom
	var atBottom = isAtBottom();
	if (!(args.text && /点击跳转\/十字街-上手指南\/持续更新/.test(args.text))) {
		$id('messages').appendChild(messageEl);
	}
	if (atBottom && myChannel != ''/*Frontpage should not be scrooled*/) {
		window.scrollTo(0, document.body.scrollHeight);
	}

	unread += 1;
	updateTitle();

	if (do_log_messages && args.nick && args.text) {
		readableLog += `\n[${date.toLocaleString()}] `
		if (args.mod) { readableLog += '(mod) ' }
		if (args.color) { readableLog += '(color:' + args.color + ') ' }
		readableLog += args.nick
		if (args.trip) { readableLog += '#' + args.trip }
		readableLog += ': ' + args.text
	}
	hook.run("after","pushmessage",[messageEl])
	return messageEl
}


function send(data) {
	if (ws && ws.readyState == ws.OPEN) {
		data = hook.run("in","send",[data])
		if(!data){
			return
		}
		ws.send(JSON.stringify(data[0]));
	}
}

/* First join then shows the ad */
if(typeof localStorageGet("cdn-advertisement") == "undefined" && document.domain == "hach.chat"){
	alert("Connection speed and security are provided by StarWAF.\nVisit link: https://www.starwaf.com/\n\n连接速度与安全性由StarWAF提供。\n访问链接：https://www.starwaf.com/")
	localStorageSet("cdn-advertisement","true")
}
/* ---Main--- */

if (myChannel == '') {
	$id('footer').classList.add('hidden');
	/*$id('sidebar').classList.add('hidden');*/
	/*I want to be able to change the settings without entering a channel*/
	$id('clear-messages').classList.add('hidden');
	$id('export-json').classList.add('hidden');
	$id('export-readable').classList.add('hidden');
	$id('users-div').classList.add('hidden');
	getHomepage();
} else {
	join(myChannel);
}

const HCER_INFO = 'CSC++ Made by 0x24a, Based on HC++ by 4n0n4me & other HiyoTeam members'
const CSCPP_VER = 'v1.0.0'
console.log(HCER_INFO)

let run = {
	copy(...args) {//copy the x-th last message
		if (args == []) {
			args = ['0']
		}
		if (args.length != 1) {
			pushMessage({ nick: '!', text: `${args.length} arguments are given while 0 or 1 is needed.` })
			return
		}
		let logList = readableLog.split('\n')
		if (logList.length <= args[0] || !do_log_messages) {
			pushMessage({ nick: '!', text: `No enough logs.` })
			return
		}
		let logItem = logList[logList.length - args[0] - 1]
		navigator.clipboard.writeText(logItem).then(function () {
			pushMessage({ nick: '*', text: "Copied: " + logItem })
		}, function () {
			pushMessage({ nick: '!', text: "Failed to copy log to clipboard." })
		});
	},
	reload(...args) {
		if (args.length != 0) {
			pushMessage({ nick: '!', text: `${args.length} arguments are given while 0 is needed.` })
			return
		}
		location.reload()
	},
	goto(...args) {
		if (args.length != 1) {
			pushMessage({ nick: '!', text: `${args.length} arguments are given while 1 is needed.` })
			return
		}
		location.href = new URL(args[0], location.href)
	},
	coderMode(...args) {
		if (!localStorageGet('coder-mode') || localStorageGet('coder-mode') != 'true') {
			coderMode()
			localStorageSet('coder-mode', true)
		} else {
			localStorageSet('coder-mode', false)
			pushMessage({ nick: '*', text: `Refresh to hide coder buttons.` })
		}
	},
	test(...args) {
		pushMessage({ nick: '!', text: `${args.length} arguments ${args}` })
	},
	about(...args) {
		let a = 'HC++ Made by 4n0n4me at hcer.netlify.app'
		console.log(a)
	},
	colorful(...args) {
		kolorful = true
	},
	raw(...args) {
		let escaped = mdEscape(cmdText.slice(4))
		pushMessage({ nick: '*', text: `\`\`\`\n${escaped}\n\`\`\`` })
		navigator.clipboard.writeText(escaped).then(function () {
			pushMessage({ nick: '*', text: "Escaped text copied to clipboard." })
		}, function () {
			pushMessage({ nick: '!', text: "Failed to copy log to clipboard." })
		});
	},
	preview(...args) {
		$id('messages').innerHTML = '';
		pushMessage({ nick: '*', text: 'Info test' })
		pushMessage({ nick: '!', text: 'Warn test' })
		pushMessage({ nick: '[test]', text: '# Title test\n\ntext test\n\n[Link test](https://hcwiki.github.io/)\n\n> Quote test' })
		$id('footer').classList.remove('hidden')
	},
	addplugin(...args) {
		if (args.length != 1) {
			pushMessage({ nick: '!', text: `${args.length} arguments are given while 1 is needed.` })
			return
		}
		// Warning
		let plugin_address=args[0]
		pushMessage({ nick: '!', text: `Warning: Please only add plugins that you trust.
		**IF YOUR HC++ IS BROKEN, THEN GO TO [/rescue-mode.html](/rescue-mode.html) AND PRESS =="REMOVE ALL PLUGINS"==**.
		or [REMOVE THIS PLUGIN](/rescue-mode.html#remove-plugin?${encodeURIComponent(plugin_address)}) now.` })
		
		//get the cmds first
		let plugins=localStorageGet("plugins")
		if(plugins != undefined){
			plugins=JSON.parse(plugins)
		}else{
			plugins=[]
		}
		//add the plugin
		plugins.push(plugin_address)
		//save
		localStorageSet("plugins",JSON.stringify(plugins))
		pushMessage({nick:"*",text:"Added plugin, refresh to apply."})
		
		//load it NOW
		// let e = document.createElement("script")
        // e.setAttribute("src", plugin_address)
        // e.setAttribute("type","application/javascript");
        // document.getElementsByTagName('head')[0].appendChild(e);
        // console.log("Loaded plugin: ", e)
		 //disabled for security.
	},
	listplugins(...args){
		let plugins=localStorageGet("plugins")
		if(plugins != undefined){
			plugins=JSON.parse(plugins)
		}else{
			plugins=[]
		}
		pushMessage({nick:"*",text:"Restigered plugins:"+JSON.stringify(plugins)})
	},
	clearplugins(...args){
		localStorageSet("plugins","[]")
		pushMessage({nick:"*",text:"Plugins cleared."})
	},
	updatelast(...args){
		send({cmd: 'updateMessage', mode: 'overwrite', text: args[0], customId: lastcid});
	},
	ignorehash(...args){
		let hash = args[0]
		if (ignoredHashs.indexOf(hash) > -1) {
			hashDeignore(hash)
			pushMessage({ nick: '*', text: `Cancelled ignoring hash ${hash}.` })
		} else {
			hashIgnore(hash)
			pushMessage({ nick: '*', text: `Ignored hash ${hash}.` })
		}
	},
	merge_config(...args){
		if (args.length != 1) {
			pushMessage({ nick: '!', text: `${args.length} arguments are given while 1 is needed.` })
			return
		}
		pushMessage({ nick: '*', text: `Click [this](https://${args[0]}/merge-config.html#${encodeURIComponent(JSON.stringify(localStorage))}) to merge config.`})
	},
	enable_camo(...args){
		if (args.length > 1) {
			pushMessage({ nick: '!', text: `${args.length} arguments are given while 1 or 0 is needed.` })
			return
		}
		if(args.length == 0){
			pushMessage({ nick: '!', text: `## Warning:\n Camo is a experimental feature and currently in test.\n**ONCE YOU ENABLED IT, YOU CAN ONLY DISABLE IT VIA CONSOLE.**\nIf you are sure about enabling it, then input \`/enable_camo iamsure\``})
		}else if(args[0] == "iamsure"){
			localStorageSet("test-camo",1)
			pushMessage({ nick: '*', text: `Camo enabled. refresh to apply.` })
		}else{
			pushMessage({ nick: '!', text: 'Unknown arguments.' })
		}
	}
}

$id('special-cmd').onclick = function () {
	let cmdText = input.value || prompt(i18ntranslate('Input command:(This is for the developers to access/test some special experimental functions.)', 'prompt'));
	if (!cmdText) {
		return;
	}
	let cmdArray = cmdText.split(' ')
	if (run[cmdArray[0]]) {
		try{
			run[cmdArray[0]](...cmdArray.slice(1))
		}catch(e){
			pushMessage({nick:"!",text:"Error when executeing \""+cmdArray[0]+"\",Send the following error messages to the developer.\n```"+e+"\n```"})
		}
	} else {
		pushMessage({ nick: '!', text: "No such function: " + cmdArray[0] })
	}
}

// Feature: let special commands could be executed just like running on server.
function parseSPCmd(input) {
	var name=input.slice(1).split(" ")[0]
	var args=input.split(" ").slice(1)
	return [name,args]
  }
function isSPCmd(text){ //P.S SPCmd == SPecial Command
	return (text.startsWith('/') && (run[text.split("/")[1].split(" ")[0]] != undefined))
}
function callSPcmd(text){
	let data = parseSPCmd(text);
	run[data[0]](...data[1])
}
function coderMode() {
	for (let char of ['(', ')', '"']) {
		let btn = document.createElement('button')
		btn.type = 'button'
		btn.classList.add('char')
		btn.textContent = char
		btn.onclick = function () {
			insertAtCursor(btn.innerHTML)
		}
		$id('more-mobile-btns').appendChild(btn)
	}
}

if (localStorageGet('coder-mode') == 'true') {
	coderMode()
}

try {
    // Load all allowed plugins in the localStorage
    var plugins = JSON.parse(localStorageGet("plugins") ?? "[]")
    console.log("Loading plugins:", plugins)
    // add into the head
    plugins.forEach(element => {
        let e = document.createElement("script")
        e.setAttribute("src", element)
        e.setAttribute("type","application/javascript");
        document.getElementsByTagName('head')[0].appendChild(e);
        console.log("Loaded plugin: ", element)
    });
} catch (e) {
    console.warn("Error when loading plugins")
}
